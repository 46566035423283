import React, { useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui'
import { useNavigate } from 'react-router-dom';
import {
    Box,
    ButtonBase,
    ClickAwayListener,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    ListItemText,
    Grid
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { logoutThunk } from "../redux/auth";

const useStyles = makeStyles()((theme) => ({
    popper: {
        zIndex: 100
    },
    logoLink: {
        textDecoration: 'none'
    },
    buttonRoot: {
        display: 'flex',
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0
    },
    userIcon: {
        borderRadius: '6px',
        backgroundColor: '#C6CFD8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '2rem',
        width: '2rem'
    },
    userIconText: {
        color: '#FFFFFF',
        textShadow: '0 1px 1px rgba(0, 0, 0, 0.25)'
    },
    menuItem: {
        padding: '8px',
        color: 'black',
        borderRadius: '5px',
        width: '170px',
        display: 'flex',
        justifyContent: 'center',
        color: '#1976d2'
    },
    avatar: {
        height: '40px',
        marginLeft: '10px',
        borderRadius: 'unset',
        backgroundColor: 'transparent'
    },
}));

const AppBar = () => {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const loggedUser = localStorage.getItem('F_U');
    const loggedInUser = JSON.parse(loggedUser);
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const userMenuAnchorRef = useRef(null);

    const handleSignOut = async () => {
        await logoutThunk();
        navigate('/')
    };

    const handleUserMenuToggle = () => {
        setUserMenuOpen(!userMenuOpen);
    };
    const handleUserMenuClose = () => {
        setUserMenuOpen(false);
    };

    return (
        <Box style={{ padding: '10px 20px' }} alignItems="flex-start" display="flex" flexWrap="wrap" justifyContent="space-between">
            <Box display="flex">
                <Grid alignItems="center" container>
                    <img alt="flowpro-logo" height={35} src="/flowpro.png" />
                </Grid>
            </Box>

            <Box display="flex">
                <ButtonBase
                    className={classes.buttonRoot}
                    component="div"
                    aria-label={'user menu'}
                    onClick={handleUserMenuToggle}
                    ref={userMenuAnchorRef}
                >
                    <Box alignItems="center" display="flex">
                        <Box ml={1.5} style={{ textAlign: 'left' }}>
                            <ListItemText primary={<span style={{ fontWeight: 500, fontSize: '14px' }}>{loggedInUser?.first_name + ' ' + loggedInUser?.last_name}</span>} secondary={<span style={{ fontSize: '10px', color: 'gray' }}>{loggedInUser?.role.replace(/_/g, " ")}</span>} />

                        </Box>
                        <Box alignItems="center" display="flex" ml={6}><ExpandMore style={{ color: '#0062b2', fontSize: '18px' }} /></Box>
                    </Box>
                </ButtonBase>

                <Popper
                    anchorEl={userMenuAnchorRef.current}
                    className={classes.popper}
                    disablePortal
                    open={userMenuOpen}
                    placement="bottom-end"
                >
                    <Box pt={1}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleUserMenuClose}>
                                <MenuList autoFocusItem={userMenuOpen} style={{ padding: '0px', textAlign: 'center' }}>
                                    <MenuItem className={classes.menuItem} onClick={handleSignOut}>Sign out</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Box>
                </Popper>
            </Box>
        </Box>
    );
}
export default AppBar;