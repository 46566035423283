import { Box, Grid, Paper, TextField, Typography, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { loginThunk } from "../redux/auth";

const useStyles = makeStyles()((theme) => ({
    main: {
        backgroundColor: '#F1F5F9',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    loginPaper: {
        margin: '0 2rem',
        maxWidth: '30rem',
        width: '80vw'
    },
    textField: {
        width: '100%'
    },
    typography: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: 1.167,
        color: '#374151'
    },
    link: {
        textDecoration: 'none'
    },
    rememberLabel: {
        color: '#6B7281'
    },
    submitButton: {
        width: '100%',
        textTransform: 'capitalize',
        borderRadius: '6px',
        background: '#007979',
        '&:hover': {
            background: '#007979',
            color: '#fff',
        }
    },
    ssoButton: {
        width: '100%',
        background: '#374151',
        color: '#fff',
        padding: '0px 10px',
        '&:hover': {
            background: '#374151',
            color: '#fff',
        }
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 'inherit'
    },
    buttonDiv1: {
        display: 'flex',
        borderRight: '1px solid #fff',
        paddingRight: '10px',
    }
}));

const Login = () => {
    const navigate = useNavigate();
    const { classes } = useStyles();
    const user = localStorage.getItem('F_U');
    const loggedInUser = JSON.parse(user);
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        email: '',
        password: '',
        remember: false
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (loggedInUser && token) {
            navigate("/list");
        }
    }, [])

    const handleSubmit = async (values, actions) => {
        actions.setSubmitting(true);
        const res = await loginThunk(values.email.trim(), values.password.trim());
        if (res.status === 200) {
            navigate("/list");
        }
        actions.setSubmitting(false);
    };

    const loginSchema = Yup.object().shape({
        email: Yup.string().email('Email is invalid').required('Required'),
        password: Yup.string().required('Please Enter your password')
    });

    return (
        <Grid className={classes.main} container spacing={0}>
            <Grid>
                <Box mb={4} ml={4}>
                    <Grid alignItems="center" justifyContent='center' container>
                        <img alt="reach-care-logo" height={60} src="/flowpro.png" />
                    </Grid>
                </Box>

                <Paper className={classes.loginPaper}>
                    <Box p={4.5}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={loginSchema}
                        >
                            {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {

                                return (
                                    <Form>
                                        <Box pb={2.5}>
                                            <Typography className={classes.typography}>
                                                Sign in to ReachCare
                                            </Typography>
                                        </Box>

                                        <Box pb={2.5}>
                                            <Box pb={0.75}>
                                                <Typography style={{ color: '#374151' }} variant="subtitle2">
                                                    Email Address
                                                </Typography>
                                            </Box>

                                            <Field
                                                as={TextField}
                                                className={classes.textField}
                                                name="email"
                                                placeholder="your@email.com"
                                                size="small"
                                                variant="outlined"
                                            />

                                            {errors.email && touched.email ? (
                                                <Typography color="error" variant="subtitle2">
                                                    {errors.email}
                                                </Typography>
                                            ) : null}
                                        </Box>

                                        <Box pb={2.5}>
                                            <Box pb={0.75}>
                                                <Grid alignItems="flex-end" container>
                                                    <Typography style={{ color: '#374151' }} variant="subtitle2">
                                                        Password
                                                    </Typography>
                                                </Grid>
                                            </Box>

                                            <Field
                                                as={TextField}
                                                className={classes.textField}
                                                name="password"
                                                size="small"
                                                variant="outlined"
                                                autoComplete='off'
                                                type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                            {errors.password && touched.password ? (
                                                <Typography color="error" variant="subtitle2">
                                                    {errors.password}
                                                </Typography>
                                            ) : null}
                                        </Box>

                                        <Box pb={0.5} mt={2} style={{ textAlign: 'center' }}>
                                            <LoadingButton
                                                className={classes.submitButton}
                                                color="primary"
                                                disabled={isSubmitting}
                                                loading={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                aria-label="sign in"
                                            >
                                                Sign In
                                            </LoadingButton>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};
export default Login;