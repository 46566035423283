import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { Box, Grid, Paper, TableContainer, Table, CircularProgress, TableRow, TableHead, TableCell, TableBody, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../components/header";

const firebaseConfig = {
    "type": "service_account",
    "project_id": "reachcare4u",
    "projectId": "reachcare4u",
    "private_key_id": "831897c8f32a64647446b64303dfcaeb4c72de96",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCh7VCvdi+yYJ3s\nRG+dWqw0lgLPlGBgd6mE73TzBwj8QjoqB6mZP3JuVYW6ITmCSuVPjo/H4oDO5qX0\nz1biZU+r4UmSbm6dg5X3ZuO1w5fk816yxTCeDGyNya5iuCD3spVfSUotUCNZvCyv\n9yfUN+to9/dKh2xrpVjeEgZajDrN9i0WDcRiv+HP/YahG+yueYVKNDd8YGk3oc38\nkm/+ZbLh1DZzNA7E1rQ3RgiT2/bOJi/u0T1gHJkOazns/tLx1EnaFCqMf9JI5T51\ngI8T/ZR5ar5z2uIYWsxvhFjfYusBaEMh5zIUiMCD/ztTwIpM+trQcPkDnGGAxQWh\nhreeC1ZFAgMBAAECggEABHh+5F3bRPuEMvsvlkl2TyDNKVoHGXUGnEr8x1WH0bFz\njIPOyYAYYG0wDW89+KoW/Y3lOcmTcNnPGpypKk1NxhsWh38H52DYrkVniBF6HY2O\nCKM+QmNpbP+ZW4tBUlVsxBBwhmvASYmufr5ivkWE+PL0RA/wjPTe34mYb1Aisu2U\n9kGdwi5klgMVdK0/Ah9JKlM13kwQETygBe8Wen8H3LSRZhiLz9D+dURTgUcRGSa3\nhnWi1UOGPlKej38bJG2jWeWtMFM+uAt0i4GeRxGBLtfgwn3gP5CZY9UKRb7ngRcj\nc98GAEH8Mj7HZhNAlhH+CXqoRpWy9X1ko71grXWhiQKBgQDQI9cj88HgcQyKRDKg\nqucHf58JG7JOQpSRjQUjI//jiZ6pHAqv8J+FuWRg9eAG1fvKcuQ0Z7cejKiO+T3O\nXUOlfjAH1jjLrytvlALM2IPBIq0PKM+iG7fzg9w0Oz/sQi5EXMBOYeWFl8H9uq1Y\nL/FyljwqxG8fWDQUPk/zycuhOwKBgQDHKSXEpBY7R8kAcGrRItFzQWoFs2VKwXRh\nJEnbPfLWDT6C5fGuKzaoXwIqw/tb0/j8A+gpv1lhga3D6tPDxFsnJzxtiPuHfT5F\ntc+lb0ntzqXPdUbuXuf/Yf7WBzWIwKGalHSZ0zh9OYL7BXqLi2QaAKBTWKWfU89B\n1MKcTE1ufwKBgEttkZu/zHTPwyGgUVCUXqEDCNk+JkHiMwEEP+ZaN3N3/f1+4gic\nhNLrOotAz2zqwxK8zCKJq3zwsG0Puew9NDSXJETSx0ZZNr56vwEJPcYSEPE7cvNm\nZCVllbl/90xhN28ixbYiGBFvy6dtrUAWL8O82hUNls+fIVBsvIC2kordAoGAHMW5\nCxuZip7oTwPuqqAZGkmNA53TOGWSZqZTdTUR7BKlwUtvCvZ7Qv/W1E7T9YQ+KEp4\nM+/M/eU6MV+vkEzBznpApKJxlDVn1bJaeVAGojCEi+Apiy5y3dZOdqU7+rG5pxdj\ncQWu14iDf7he5+IPaCJHB0N7SfVVr/EI3IO5fAkCgYEAr8SlqszI2Zi+O3izKeE1\nAXKjzE5DRrG/kbG8gwq6sdEyoM1ZhdW3/feUgZEI+fWh1nYBNlZZNxw5SVf+zekM\nUVimMlfa3i0pDaHrHyiRhNT195bpHwbwjFJcUMs57l//R1bNOzYJbrx/sbG29d94\nA4WSuLHHCe2NhXPkcY1SFto=\n-----END PRIVATE KEY-----\n",
    "client_email": "firebase-adminsdk-zuj9r@reachcare4u.iam.gserviceaccount.com",
    "client_id": "106402483732240208252",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-zuj9r%40reachcare4u.iam.gserviceaccount.com",
    "universe_domain": "googleapis.com"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Page = () => {
    const navigate = useNavigate();
    const user = localStorage.getItem('F_U');
    const loggedInUser = user && JSON.parse(user);
    const [rows, setRows] = useState([]);
    const [excel, setExcel] = useState([]);
    const [load, setLoad] = useState(false);

    // Get a list of cities from your database
    async function getAnalytics(db) {
        const dataCol = collection(db, 'analytics');
        const dataSnapshot = await getDocs(dataCol);
        const dataList = dataSnapshot.docs.map(doc => doc.data());
        setRows(dataList);
        if (dataList?.length > 0) {
            const arr = [];
            for (let i = 0; i < dataList.length; i++) {
                const info = {
                    "Patient ID": dataList[i]?.patient_number || '',
                    "Patient Name": (dataList[i]?.last_name && dataList[i]?.first_name) ? `${dataList[i]?.last_name} ${dataList[i]?.first_name})` : '',
                    "Email": dataList[i]?.email || '',
                    "Last Login": dataList[i]?.last_login || '',
                    "Family Sharing": dataList[i]?.is_family_sharing ? 'Yes' : 'No',
                    "Family Sharing Time": dataList[i]?.family_sharing_time || '',
                    "LP Viewed": dataList[i]?.is_lp_viewed ? 'Yes' : 'No',
                    "MF Viewed": dataList[i]?.is_mf_viewed ? 'Yes' : 'No',
                    "Notification Clicked": dataList[i]?.is_notification_clicked ? 'Yes' : 'No',
                    "SFP Viewed": dataList[i]?.is_sfp_viewed ? 'Yes' : 'No',
                    "SPI Viewed": dataList[i]?.is_spi_viewed ? 'Yes' : 'No',
                    "Video Watched": dataList[i]?.is_video_watched ? 'Yes' : 'No',
                    "BHAP Chatbot Open Time": dataList[i]?.bhap_chatbot_open_time || '',
                    "BHAP Chatbot Completed Time": dataList[i]?.bhap_chatbot_completed_time || '',
                    "BHAP Viewed Time": dataList[i]?.bhap_viewed_time || '',
                    "BHAP Video Watched Time": dataList[i]?.bhap_video_watched_time?.join(', ') || '',
                    "LP Chatbot Open Time": dataList[i]?.lp_chatbot_open_time || '',
                    "LP Chatbot Completed Time": dataList[i]?.lp_chatbot_completed_time || '',
                    "LP Viewed Time": dataList[i]?.lp_viewed_time || '',
                    "LP Video Watched Time": dataList[i]?.lp_video_watched_time?.join(', ') || '',
                    "MF Chatbot Open Time": dataList[i]?.mf_chatbot_open_time || '',
                    "MF Chatbot Completed Time": dataList[i]?.mf_chatbot_completed_time || '',
                    "MF Viewed Time": dataList[i]?.mf_viewed_time || '',
                    "MF Video Watched Time": dataList[i]?.mf_video_watched_time?.join(', ') || '',
                    "Notification Clicked Count": dataList[i]?.notification_clicked_count,
                    "SFP Chatbot Open Time": dataList[i]?.sfp_chatbot_open_time || '',
                    "SFP Chatbot Completed Time": dataList[i]?.sfp_chatbot_completed_time || '',
                    "SFP Viewed Time": dataList[i]?.sfp_viewed_time || '',
                    "SP Video Watched Time": dataList[i]?.sp_video_watched_time || '',
                    "SPI Chatbot Open Time": dataList[i]?.spi_chatbot_open_time || '',
                    "SPI Chatbot Completed Time": dataList[i]?.spi_chatbot_completed_time || '',
                    "SPI Viewed Time": dataList[i]?.spi_viewed_time || '',
                    "SPI Video Watched Time": dataList[i]?.spi_video_watched_time?.join(', ') || '',
                    "VC Chatbot Open Time": dataList[i]?.vc_chatbot_open_time || '',
                    "VC Chatbot Completed Time": dataList[i]?.vc_chatbot_completed_time || '',
                    "VC Viewed Time": dataList[i]?.vc_viewed_time || '',
                }
                arr.push(info);
            }
            setExcel(arr);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (loggedInUser && token) {
            navigate("/");
        } else {
            getAnalytics(db);
        }
    }, [])

    const getDate = (date) => {
        if (date !== '' && date !== 'undefined') {
            let newDate = new Date(date);
            return newDate ?
                newDate.toLocaleDateString("en-us", { day: "numeric" }) +
                "/" +
                newDate.toLocaleDateString("en-us", { month: "numeric" }) +
                "/" +
                newDate.toLocaleDateString("en-us", { year: "numeric" }) +
                ', ' +
                newDate.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                }) : ''
        } else {
            return ''
        }
    }

    const getDate2 = (date) => {
        const array = [];
        for (let i = 0; i < date.length; i++) {
            let newDate = new Date(date);
            const conDate = newDate ?
                newDate.toLocaleDateString("en-us", { day: "numeric" }) +
                "/" +
                newDate.toLocaleDateString("en-us", { month: "numeric" }) +
                "/" +
                newDate.toLocaleDateString("en-us", { year: "numeric" }) +
                ', ' +
                newDate.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                }) : ''
            array.push(conDate);
        }
        return array;
    }

    const JSONToCSVConvertor = (ShowLabel) => {
        setLoad(true);
        var arrData = typeof excel !== "object" ? JSON.parse(excel) : excel;
        var CSV = "";
        if (ShowLabel) {
            var row = "";
            for (var index in arrData[0]) {
                if (index == "User ID" || index == "User Name" || index == "Email" || index == "Last Login" || index == "Family Sharing" || index == "Family Sharing Time" || index == "LP Viewed" || index == "MF Viewed" || index == "Notification Clicked" || index == "SFP Viewed" || index == "SPI Viewed" || index == "Video Watched" || index == "BHAP Chatbot Open Time" || index == "BHAP Chatbot Completed Time" ||
                    index == "BHAP Viewed Time" || index == "BHAP Video Watched Time" || index == "LP Chatbot Open Time" || index == "LP Chatbot Completed Time" || index == "LP Viewed Time" || index == "LP Video Watched Time" || index == "MF Chatbot Open Time" || index == "MF Chatbot Completed Time" || index == "MF Viewed Time" || index == "MF Viewed" || index == "MF Video Watched Time" || index == "Notification Clicked Count"
                    || index == "SFP Chatbot Open Time" || index == "SFP Chatbot Completed Time" || index == "SFP Viewed Time" || index == "SP Video Watched Time" || index == "SPI Chatbot Open Time" || index == "SPI Chatbot Completed Time" || index == "SPI Viewed Time" || index == "SPI Video Watched Time" || index == "VC Chatbot Open Time" || index == "VC Chatbot Completed Time" || index == "VC Viewed Time") {
                    row += index + ",";
                }
            }
            row = row.slice(0, -1);
            CSV += row + "\r\n";
        }
        for (var i = 0; i < arrData.length; i++) {
            var row = "";
            for (var index in arrData[i]) {
                if (index == "User ID" || index == "User Name" || index == "Email" || index == "Last Login" || index == "Family Sharing" || index == "Family Sharing Time" || index == "LP Viewed" || index == "MF Viewed" || index == "Notification Clicked" || index == "SFP Viewed" || index == "SPI Viewed" || index == "Video Watched" || index == "BHAP Chatbot Open Time" || index == "BHAP Chatbot Completed Time" ||
                    index == "BHAP Viewed Time" || index == "BHAP Video Watched Time" || index == "LP Chatbot Open Time" || index == "LP Chatbot Completed Time" || index == "LP Viewed Time" || index == "LP Video Watched Time" || index == "MF Chatbot Open Time" || index == "MF Chatbot Completed Time" || index == "MF Viewed Time" || index == "MF Viewed" || index == "MF Video Watched Time" || index == "Notification Clicked Count"
                    || index == "SFP Chatbot Open Time" || index == "SFP Chatbot Completed Time" || index == "SFP Viewed Time" || index == "SP Video Watched Time" || index == "SPI Chatbot Open Time" || index == "SPI Chatbot Completed Time" || index == "SPI Viewed Time" || index == "SPI Video Watched Time" || index == "VC Chatbot Open Time" || index == "VC Chatbot Completed Time" || index == "VC Viewed Time") {
                    row += '"' + arrData[i][index] + '",';
                } else { }
            }
            row.slice(0, row.length - 1);
            CSV += row + "\r\n";
        }
        if (CSV === "") {
            alert("Invalid data");
            return;
        }
        //Generate a file name
        var fileName = 'UserLogReports';
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);
        var link = document.createElement("a");
        link.href = uri;
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoad(false);
    };

    return (loggedInUser &&
        <>
            <Header />
            <Grid container spacing={0} style={{ padding: '20px' }}>
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                    <Typography>User log list</Typography>
                    <Button variant="contained" onClick={() => JSONToCSVConvertor(true)}>Export To Excel</Button>
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow style={{ background: '#f9fafb' }}>
                                <TableCell>Patient ID</TableCell>
                                <TableCell>Patient Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Last Login</TableCell>
                                <TableCell>Family Sharing</TableCell>
                                <TableCell>Family Sharing Time</TableCell>
                                <TableCell>LP Viewed</TableCell>
                                <TableCell>MF Viewed</TableCell>
                                <TableCell>Notification Clicked</TableCell>
                                <TableCell>SFP Viewed</TableCell>
                                <TableCell>SPI Viewed</TableCell>
                                <TableCell>Video Watched</TableCell>
                                <TableCell>BHAP Chatbot Open Time</TableCell>
                                <TableCell>BHAP Chatbot Completed Time</TableCell>
                                <TableCell>BHAP Viewed Time</TableCell>
                                <TableCell>BHAP Video Watched Time</TableCell>
                                <TableCell>LP Chatbot Open Time</TableCell>
                                <TableCell>LP Chatbot Completed Time</TableCell>
                                <TableCell>LP Viewed Time</TableCell>
                                <TableCell>LP Video Watched Time</TableCell>
                                <TableCell>MF Chatbot Open Time</TableCell>
                                <TableCell>MF Chatbot Completed Time</TableCell>
                                <TableCell>MF Viewed Time</TableCell>
                                <TableCell>MF Video Watched Time</TableCell>
                                <TableCell>Notification Clicked Count</TableCell>
                                <TableCell>SFP Chatbot Open Time</TableCell>
                                <TableCell>SFP Chatbot Completed Time</TableCell>
                                <TableCell>SFP Viewed Time</TableCell>
                                <TableCell>SP Video Watched Time</TableCell>
                                <TableCell>SPI Chatbot Open Time</TableCell>
                                <TableCell>SPI Chatbot Completed Time</TableCell>
                                <TableCell>SPI Viewed Time</TableCell>
                                <TableCell>SPI Video Watched Time</TableCell>
                                <TableCell>VC Chatbot Open Time</TableCell>
                                <TableCell>VC Chatbot Completed Time</TableCell>
                                <TableCell>VC Viewed Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row) => (
                                <TableRow
                                    key={row?.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{row?.patient_number}</TableCell>
                                    <TableCell component="th" scope="row">{row?.last_name} {row?.first_name}</TableCell>
                                    <TableCell>{row?.email}</TableCell>
                                    <TableCell>{getDate(row?.last_login)}</TableCell>
                                    <TableCell>{row?.is_family_sharing ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{getDate(row?.family_sharing_time)}</TableCell>
                                    <TableCell>{row?.is_lp_viewed ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{row?.is_mf_viewed ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{row?.is_notification_clicked ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{row?.is_sfp_viewed ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{row?.is_spi_viewed ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{row?.is_video_watched ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{getDate(row?.bhap_chatbot_open_time)}</TableCell>
                                    <TableCell>{getDate(row?.bhap_chatbot_completed_time)}</TableCell>
                                    <TableCell>{getDate(row?.bhap_viewed_time)}</TableCell>
                                    <TableCell>{getDate2(row?.bhap_video_watched_time)?.join(', ')}</TableCell>
                                    <TableCell>{getDate(row?.lp_chatbot_open_time)}</TableCell>
                                    <TableCell>{getDate(row?.lp_chatbot_completed_time)}</TableCell>
                                    <TableCell>{getDate(row?.lp_viewed_time)}</TableCell>
                                    <TableCell>{getDate2(row?.lp_video_watched_time)?.join(', ')}</TableCell>
                                    <TableCell>{getDate(row?.mf_chatbot_open_time)}</TableCell>
                                    <TableCell>{getDate(row?.mf_chatbot_completed_time)}</TableCell>
                                    <TableCell>{getDate(row?.mf_viewed_time)}</TableCell>
                                    <TableCell>{getDate2(row?.mf_video_watched_time)?.join(', ')}</TableCell>
                                    <TableCell>{row?.notification_clicked_count}</TableCell>
                                    <TableCell>{getDate(row?.sfp_chatbot_open_time)}</TableCell>
                                    <TableCell>{getDate(row?.sfp_chatbot_completed_time)}</TableCell>
                                    <TableCell>{getDate(row?.sfp_viewed_time)}</TableCell>
                                    <TableCell>{getDate(row?.sp_video_watched_time)}</TableCell>
                                    <TableCell>{getDate(row?.spi_chatbot_open_time)}</TableCell>
                                    <TableCell>{getDate(row?.spi_chatbot_completed_time)}</TableCell>
                                    <TableCell>{getDate(row?.spi_viewed_time)}</TableCell>
                                    <TableCell>{getDate2(row?.spi_video_watched_time)?.join(', ')}</TableCell>
                                    <TableCell>{getDate(row?.vc_chatbot_open_time)}</TableCell>
                                    <TableCell>{getDate(row?.vc_chatbot_completed_time)}</TableCell>
                                    <TableCell>{getDate(row?.vc_viewed_time)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    );
};
export default Page;