import { postHttp, updateToken, _getHost, _getHeaders } from './api';

export const loginThunk = async (email, password) => {
    try {
        const fullUrl = `${_getHost()}${'/user/auth/login'}`;
        const result = await fetch(fullUrl, {
            method: 'POST',
            headers: _getHeaders({ 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                loginId: email,
                password: password
            })
        });
        if (result.ok) {
            const tokenResp = await result.json();
            if (tokenResp.status === "ACTIVE") {
                await updateToken(tokenResp.token);
                tokenResp.user_role.first_name = tokenResp.user.first_name;
                tokenResp.user_role.last_name = tokenResp.user.last_name;
                tokenResp.user_role.is_sso_user = tokenResp.user.is_sso_user;
                tokenResp.user_role.referral_id = tokenResp.referral_id;
                tokenResp.user_role.organization_name = tokenResp.organization_name;
                await localStorage.setItem('F_U', JSON.stringify(tokenResp.user_role));
                return { status: 200 };
            }
        }
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            localStorage.removeItem('F_T');
            localStorage.removeItem('F_U');
            return { status: 401, msg: 'Invalid Credentials!' };
        } else {
            return { status: 401, msg: 'Invalid Credentials!' };
        }
    }
};
export const logoutThunk = async () => {
    try {
        const loggedUser = localStorage.getItem('F_U');
        const userRoleId = JSON.parse(loggedUser);
        const res = await postHttp('/user/auth/logout', {
            user_id: userRoleId.user_id,
            user_role_id: userRoleId.id
        });
        localStorage.removeItem('F_T');
        localStorage.removeItem('F_U');
        return res;
    } catch (e) {
        localStorage.removeItem('F_T');
        localStorage.removeItem('F_U');
    }
};