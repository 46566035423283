import { Routes, Route } from "react-router-dom";
import Login from './pages/login';
import ListPage from './pages/logs';

function App() {

  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/list" element={<ListPage />} />
      </Routes>
    </div>
  );
}

export default App;