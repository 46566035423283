// const url = 'http://3.138.156.236:3001';   //programination_build

const url = 'https://api.formflash.com';  //dev1_build

// const url = 'https://dev4-formflash.ummsresearch.org'; //dev4_build 

export class HttpError extends Error {
    message;
    code;
    info;
    constructor(message, code, info) {
        super(message);
        this.name = 'HttpError';
        this.message = message;
        this.code = code;
        this.info = info;
    }
}

export const _getHost = () => {
    return window.location.hostname === url //'https://www.formflash.com'
        ? url : url;
};

export const _getHeaders = (inputHeaders) => {
    let headers = inputHeaders || {};
    headers['Accept'] = 'application/json';
    const headersWithAuth = _getToken()
        ? {
            ...headers,
            Authorization: `Bearer ${_getToken()}`
        }
        : headers;
    return headersWithAuth;
};

export const getHttp = async (url_path) => {
    const fullUrl = `${_getHost()}${url_path}`;
    const result = await fetch(fullUrl, {
        method: 'GET',
        headers: _getHeaders()
    });
    if (!result.ok) {
        const errorResponse = await result.text();
        const errorMessage = errorResponse ? JSON.parse(errorResponse).message : result.statusText;
        const code = result.status;
        throw new HttpError(errorMessage, code, result);
    } else {
        return await result.json();
    }
};

export const postHttp = async (url_path, body) => {
    const fullUrl = `${_getHost()}${url_path}`;
    const result = await fetch(fullUrl, {
        method: 'POST',
        headers: _getHeaders({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(body)
    });
    if (!result.ok) {
        const errorResponse = await result.text();
        const errorMessage = errorResponse ? JSON.parse(errorResponse).message : result.statusText;
        throw new HttpError(errorMessage, result.status, result);
    }
    return await result.json();
};

// Auth token
let token = localStorage.getItem('F_T');
const _getToken = () => {
    return token;
};
export const updateToken = (newToken) => {
    localStorage.setItem('F_T', newToken);
    token = newToken;
};
export const isLoggedIn = () => {
    return token !== null;
};

// Validate when file first loads
if (token) {
    getHttp('/user/auth/validate').catch((e) => {
        localStorage.removeItem('F_T');
        token = null;
    });
};